@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

video::-webkit-media-controls-enclosure {
  width: 80%;
}
